/* Title & options */
/* Estilos existentes */
.container-title{
    width: 100%;
}

.title-dash h1{
    /* Estilos para el título */
}

.button-option-title {
    background-color: #b4bd009b;
    color: black;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    min-width: 15rem;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem; /* Espacio entre botones */
    /* Ajusta la anchura según sea necesario, o elimina esta línea si quieres que Bootstrap maneje la anchura */
  }
  
  .button-option-download{
    background-color: #dadada;
    color: black;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    min-width: 5rem;
    padding: 0.2rem .5rem;
    margin: 0 0.5rem; /* Espacio entre botones */
    /* Ajusta la anchura según sea necesario, o elimina esta línea si quieres que Bootstrap maneje la anchura */
  }

  .button-option-title-edit{
    background-color: #b4bd009b;
    color: black;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    min-width: 3rem;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem; /* Espacio entre botones */
  }

/* Media queries para dispositivos móviles y tabletas */
@media (max-width: 960px) {
    .button-option-title {
      width: 100%; /* Hace que los botones ocupen todo el ancho disponible en dispositivos pequeños */
      margin: 0.5rem 0; /* Ajusta los márgenes para dispositivos pequeños */
    }
  }



.d-flex {
    display: flex;
  }
  
  .flex-grow-1 {
    flex-grow: 1; /* Ocupa todo el espacio disponible */
    width: 100%; /* Ocupa todo el ancho de la pantalla */

  }
  



  /* cards modulo */

  .cardModulo{
    border-radius: 5px;
    box-shadow: 0px 4px 3px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 15px 4px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 23px -3px rgba(0,0,0,0.75);
  }


  .boton-flotante {
    position: fixed;
    bottom: 20px; /* Puedes ajustar estos valores según tu preferencia */
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #00B100; /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    border-radius: 50%; /* Para que el botón sea circular */
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra para efecto de elevación */
  }