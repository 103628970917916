/* table */
.mi-tabla-scroll {
  max-height: 10em;
  /* Ajusta esto según la altura de tus filas y cabecera */
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 5px !important;
}

.table-compras {
  max-height: 45em !important;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 5px !important;
}

.indicadorAccionesNegro {
  background-color: #000;
  color: white;
  border-radius: 20px;
  border: none;
}

.indicadorAccionesDisabled {
  background-color: #736f6f;
  color: rgb(0, 0, 0);
  border-radius: 20px;
  border: none;
}

.btnVer {
  background-color: #00B100;
  color: white;
  font-size: 1em;
  border: none;
  border-radius: 10px;
}

.contentModalDetalleCompra {
  max-height: 80%;
  overflow-x: auto;
  overflow-x: hidden;
}

.table-thead {
  background-color: #00B100;
}

.color-white {
  color: white !important;
}

.color-black {
  color: black !important;
}

.table-tbody {
  background-color: #E0E599;
}

.table thead th {
  color: white;
  background-color: #B2BD0099;
  /* Color de fondo para thead */
  border: none;
  /* Elimina los bordes de los th */
}

.imgDetalleCompra {
  width: 100%;
  height: 40em;
  border: 1px solid black;
}

.button-option-title-custom {
  background-color: #b4bd009b;
  color: black;
  font-weight: 400;
  border-radius: 5px;
  border: none;
  min-height: 4em;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  /* Espacio entre botones */
  /* Ajusta la anchura según sea necesario, o elimina esta línea si quieres que Bootstrap maneje la anchura */
}

.button-option-title-custom-active {
  background-color: #00B100;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  min-height: 4em;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  /* Espacio entre botones */
  /* Ajusta la anchura según sea necesario, o elimina esta línea si quieres que Bootstrap maneje la anchura */
}




/* PROVEEDORES */
.containerCardsProveedores {
  background-color: white;
  max-height: 70vh;
  /* Ajusta este valor según necesites */
  overflow-y: auto;
}

.tableProveedores {
  max-height: 70vh !important;
  height: 60em;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 7px !important;
  border: 1px solid rgba(186, 184, 184, 0.485);
}

.cardProveedor {
  border-radius: 10px;
  border: none;
  background-color: #F4F4F4;
  box-shadow: 5px 5px 5px 0px rgba(163, 163, 163, 0.529);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(163, 163, 163, 0.529);
  -moz-box-shadow: 5px 5px 5px 0px rgba(163, 163, 163, 0.529);
}

.buttonDetallesProveedores {
  border-radius: 7px;
  height: 2.3em;
  background-color: #DADE92;
  border: none;
}

/* cargar contrato */

.carga-documento {
  border: 2px dashed #ccc;
  border-radius: 7px;
  text-align: center;
  max-height: 15em;
  height: 15em;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}

.label-documento {
  cursor: pointer;
}

.btn-outline-secondary {
  position: absolute;
  bottom: 10px;
  right: 10px;
}


.containerProductos {
  background-color: #E0E599;
  border: none;
  border-radius: 6px;
  max-height: 20em;
  height: 16.5em;
  overflow-y: auto;
}

.productoProveedor {
  border-bottom: 1px solid#00B100;
}

.button-option-detalle-compras {
  background-color: #b4bd009b;
  color: black;
  font-weight: 400;
  border-radius: 5px;
  border: none;
  min-height: 2.3em;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  /* Espacio entre botones */
  /* Ajusta la anchura según sea necesario, o elimina esta línea si quieres que Bootstrap maneje la anchura */
}

.button-option-detalle-compras-green {
  background-color: #00B100;
  color: white;
  font-weight: 400;
  border-radius: 5px;
  border: none;
  min-height: 2.3em;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  /* Espacio entre botones */
  /* Ajusta la anchura según sea necesario, o elimina esta línea si quieres que Bootstrap maneje la anchura */
}



/* DETALLE PROVEEDOR */
.btnOpcionDetalleProveedor {
  background-color: #E0E599;
  color: #5C5C5C;
  border: none;
  font-weight: 600;
}

.btnOpcionDetalleProveedorActive {
  background-color: #00B100;
  color: white;
  font-weight: 600;
}

.tableInformacionGeneral {
  max-height: 100% !important;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 7px !important;
  border: 1px solid rgba(186, 184, 184, 0.485);
}

.inputDetalleProveedor {
  border: none;
  background-color: #F4F4F4;
  border-radius: 8px;
  height: 2.3rem;
  margin-bottom: 7px;
}

.imgProveedorDetalle {
  width: 35em;
}

.titleHistorial {
  font-size: 2rem;
  color: #5C5C5C;
}

.buttonAgregraDetalle {
  background-color: #00B100;
  color: white;
  border: none;
  border-radius: 15px;
}

.table-proveedores {
  max-height: 70em !important;
  height: 40em !important;
  font-size: .7rem;
  overflow-y: auto;
}


/* Cotizacion */
.buttonPresentacion {
  border-radius: 10px;
  border: none;
  background-color: white;
}


.radioCotizacion {
  display: none;
  /* Oculta el input radio original */
}

.custom-radio {
  position: relative;
  padding-left: 25px;
  /* Espacio para la visualización del cuadrado */
  cursor: pointer;
}

.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  /* Tamaño del cuadrado */
  width: 20px;
  /* Tamaño del cuadrado */
  background-color: #eee;
  border: 1px solid #ddd;
}

/* Al seleccionar el radio, cambiar el fondo del cuadrado */
.custom-radio input:checked~.checkmark {
  background-color: #2196F3;
}

/* Crear un indicador de selección cuando el radio esté seleccionado */
.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked~.checkmark:after {
  display: block;
  left: 7px;
  /* Centrar el indicador en el cuadrado */
  top: 3px;
  width: 6px;
  /* Tamaño del indicador */
  height: 10px;
  /* Tamaño del indicador */
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.table-thead-compra {
  background-color: white;
  color: black;
}