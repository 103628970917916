.mkp-btn-primary{
    background-color: #b4bd009b;
    color: black;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    border: none;
    margin: 1px;
    padding: 2px 10px 2px;
}
.mkp-btn-primary:hover,
.mkp-btn-primary:active{
    background-color: #00B100;
    color: white;
}

.mkp-btn-primary.tabActive{
    background-color: #00B100;
    color: white;
}


.mkp-btn-secondary{
    background-color: #00B100;
    color: black;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    border: none;
    margin: 1px;
    padding: 2px 10px 2px;
}
.mkp-btn-secondary:hover,
.mkp-btn-secondary:active{
    background-color: #033603;
    color: white;
}

.mkp-btn-secondary.tabActive{
    background-color: #00B100;
    color: white;
}

.mkp-btn-danger{
    background-color: #5e0101;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    border: none;
    margin: 1px;
    padding: 2px 10px 2px;
}
.mkp-btn-danger:hover,
.mkp-btn-danger:active{
    background-color: #033603;
    color: white;
}

.mkp-btn-danger.tabActive{
    background-color: #5e0101;
    color: white;
}