/* SIDEBAR */

.sidebar {
  width: 230px;
  min-height: 100vh;
  height: 100%;
  transition: all 0.3s;
  box-shadow: 6px 0px 10px 2px rgba(0, 0, 0, 0.25);
}

.sidebar.collapsed {
  width: 80px;
}

.toggle-btn {
  box-shadow: 6px 0px 10px 2px rgba(0, 0, 0, 0.25);
  background: white;
  border: none;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  top: 4rem;
  /* Centrar verticalmente */
  width: 1.5rem;
  height: 4rem;
  /* Altura completa del viewport */
  transform: translateY(-45%);
  /* Centrado exacto y también aplicar para la altura alargada */
  transition: left 0.3s, transform 0.3s;
  /* Ajustar la velocidad de la transición como prefieras */
  z-index: 2;
  left: 230px;
  /* Ajustar según el ancho inicial del sidebar */
  cursor: pointer;
  /* Cambiar el cursor a pointer para indicar que es clickable */
}

.toggle-btn-collapsed {
  left: 80px;
  /* Ajustar según el ancho del sidebar cuando está colapsado */
}

.sidebar-icon {
  flex-shrink: 0;
  /* Evita que los iconos se escalen cuando el texto se oculta */
}

.sidebar-text {
  flex-grow: 1;
  text-align: start;
}

#logout {
  border-radius: 5px;
  bottom: 0;
  /* Posición fija en la parte inferior */
  background-color: #00B100;
  /* Color de fondo para el botón de cerrar sesión */
  color: white;
  /* Color del texto para el botón de cerrar sesión */
}

.nav-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 16px
}

.nav-item a {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: start;
  color: black;
}

.nav-item:hover {
  background-color: #B2BD0066;
}

.imageMenu {
  padding-right: 29px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* Alinea el logo a la izquierda y el botón de hamburguesa a la derecha */
    padding: 0.5rem 1rem;
    /* Ajusta el padding según necesites */
  }

  .toggle-btn {
    position: fixed;
    /* Mantiene el botón de hamburguesa fijo en la parte superior derecha */
    top: 0.5rem;
    /* Espacio desde la parte superior */
    right: 1rem;
    /* Espacio desde la derecha */
    width: auto;
    /* Auto-ajuste el ancho */
    height: auto;
    /* Auto-ajuste la altura */
    background: transparent;
    /* Fondo transparente para el botón de hamburguesa */
    border-radius: 0;
    /* Elimina el border-radius si existe */
  }

  .nav-item,
  .nav-item a {
    display: none;
    /* Oculta los ítems del menú en la versión móvil hasta que se haga clic en el botón de hamburguesa */
  }

  .sidebar.collapsed .nav-item,
  .sidebar.collapsed .nav-item a {
    display: flex;
    /* Muestra los ítems del menú cuando el sidebar está colapsado */
    flex-direction: column;
    /* Alinea los ítems del menú verticalmente */
    width: 100%;
    /* Ajusta el ancho al 100% */
  }

  #logout {
    display: none;
    /* Oculta el botón de cerrar sesión en la vista móvil hasta que el menú esté colapsado */
  }

  .sidebar.collapsed #logout {
    display: flex;
    /* Muestra el botón de cerrar sesión cuando el sidebar está colapsado */
  }
}