.LoginContainer {
    display: flex;
    height: 100vh; /* Altura total de la ventana */
    width: 100vw; /* Ancho total de la ventana */
    overflow: hidden; /* Oculta cualquier contenido que se desborde */
}


.imageLoginContainer {
    flex: 1; /* Ocupa la mitad del espacio disponible en pantallas grandes */
    display: flex; /* Usa flexbox para el diseño */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    overflow: hidden; /* Asegúrate de que cualquier imagen que se desborde se oculte */
}

.imgLogin {
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh; /* Fuerza a la imagen a tomar el 100% de la altura de la ventana */
    min-width: 30vw; /* Asegúrate de que la imagen tenga al menos la mitad del ancho de la ventana para cubrir su contenedor */
    object-fit: cover; /* Cubre el contenedor manteniendo las proporciones, pero puedes cambiarlo a 'fill' si la distorsión no es un problema */
}

.textImgLogin{
    color: #00B100;
    text-align: center;
    font-size: 1.5rem;
}

.inputsLoginContainer {
    flex: 1; /* Toma la mitad del espacio disponible */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribuye el espacio entre el logo y los inputs */
    /* align-items: center; */
    padding: 180px; /* Añade un poco de padding */
}

.logoContainer {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Estilos para el contenedor del logo, ajusta según sea necesario */
}

.formContainer {
    width: 100%; /* Ocupa todo el ancho disponible dentro del contenedor */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 35%; */
}

.textLabel{
    text-align: start;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.inputLogin {
    margin: 0px 0; /* Añade espacio entre los inputs */
    background-color: #B2BD0066;
    border-radius: 5px;
    padding: 10px;
    height: 5vh !important;
    width: 85vw !important;
    font-size: 1rem;
    border: none;
    /* Añade más estilos aquí según sea necesario */
}

.login-form{
    /* height: 5vh !important; */
    display: flex;
    flex-direction: column;
    width: 100% !important;
}

.button-login {
    color: white;
    padding: 10px;
    font-size: 1rem;
    width: 85vw !important;
    height: 5vh;
    background-color: #00B100;
    border-radius: 5px;
    border: none;

    /* Estilos para el botón */
}

.text-label-login{
    font-weight: 400;
}

@media (min-width: 960px) {



    .inputLogin {
        margin: 0px 0; /* Añade espacio entre los inputs */
        background-color: #B2BD0066;
        border-radius: 5px;
        padding: 10px;
        height: 5vh !important;
        width: 40vw !important;
        font-size: 1rem;
        border: none;
        /* Añade más estilos aquí según sea necesario */
    }
    
    .login-form{
        /* height: 5vh !important; */
        display: flex;
        flex-direction: column;
        width: 100% !important;
    }
    
    .button-login {
        color: white;
        padding: 10px;
        font-size: 1rem;
        width: 40vw !important;
        height: 5vh;
        background-color: #00B100;
        border-radius: 5px;
        border: none;
    
        /* Estilos para el botón */
    }

    .but-log {
        color: white;
        padding: 10px;
        font-size: 1rem;
        width: 40vw !important;
        height: 5vh;
        background-color: #00B100;
        border-radius: 5px;
        border: none;
    
        /* Estilos para el botón */
    }
}
