.content-scrollable {
    max-height: calc(100vh - 100px); /* Ajusta la altura máxima según tu necesidad */
    overflow-y: auto; /* Habilita el desplazamiento vertical */
    padding-right: 20px; /* Añade algo de espacio a la derecha para evitar que el contenido se corte */
    margin-right: -20px; /* Neutraliza el espacio adicional a la derecha para mantener el diseño */
  }
  
  /* Oculta las barras de desplazamiento pero mantiene la funcionalidad */
  .content-scrollable::-webkit-scrollbar {
    display: none; /* Para navegadores WebKit como Chrome y Safari */
  }
  
  .content-scrollable {
    -ms-overflow-style: none; /* Para Internet Explorer 10+ */
    scrollbar-width: none; /* Para Firefox */
  }
  