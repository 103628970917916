.cardCiudad {
  border-radius: 15px;
  box-shadow: 0px 4px 3px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 3px 15px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 23px -3px rgba(0, 0, 0, 0.75);
}

.titleCiudadCard {
  color: white;
  background-color: #B2BD00;
  width: 100%;
  font-weight: 800;
}

.descriptionCardCiudad {
  font-weight: 700;
}

.spaceCardCiudad {
  padding: 0.3rem 1rem;
}

.spaceCardCiudadLast {
  margin-bottom: 10px !important;
}

.cardFiltros {
  border-radius: 5px;
  box-shadow: 0px 4px 3px -3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 3px 15px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 23px -3px rgba(0, 0, 0, 0.75);
}

.numberCardFiltros {
  font-weight: 700;
}

.button-option-filtros {
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
}

.inventarios-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.no-sucursales-container {
  height: 70vh;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-sucursales-content {
  text-align: center;
}

.btn-noSucursales {
  padding: .5rem 3rem .5rem 3rem;
  border: none;
  background-color: #B2BD0066;
  border-radius: 10px;
}





/* AÑADIR INVENTARIO */

.buttonAñadirInventario {
  background-color: #04B606;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  min-width: 15rem;
  padding: 0.5rem 1rem;
  margin: 0 0rem;
  /* Espacio entre botones */
  /* Ajusta la anchura según sea necesario, o elimina esta línea si quieres que Bootstrap maneje la anchura */
}

.buttonAñadirInventario {
  background-color: #04B606;
  
}

.buttonAñadirInventarioOption {
  background-color: #04b6079f;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  min-width: 15rem;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  /* Espacio entre botones */
}

.inputAñadirInventario {
  border: 3px solid rgba(160, 158, 158, 0.419);
  background-color: #b4bd009b;

  margin-bottom: 10px;
}

.inputAñadirInventarioMitad {
  border: none;
  border: 3px solid rgba(160, 158, 158, 0.419);
  background-color: #b4bd009b;
  border-radius: 8px;
  height: 3rem;
  margin-bottom: 30px;
}

.labelAñadir {
  font-weight: 600;
  font-size: 100%;
}

.titleAñadir {
  font-size: 1.5rem;
}

.inputRadioAñadir {
  width: 30px;
  height: 30px;
}

.labelAñadir {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-box {
  border: 2px dashed #ccc;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  width: 100%;
  height: 9rem;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
}

.upload-box:hover {
  border-color: #86b7fe;
}

.upload-icon {
  /* Establece tus propios estilos para el ícono aquí */
  margin-bottom: 10px;
}

.upload-text {
  color: #757575;
  font-size: 14px;
  margin-bottom: 0;
}

/* Estilos para el input tipo file en caso de que decidas usarlo */
.input-file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}


.table-responsive {
  border-radius: 15px;
  height: 100%;
  overflow: hidden;
  /* Asegura que el border-radius se aplique a los hijos */
  border: 1px solid #a4a4a465;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Opcional: sombra suave alrededor de la tabla */
}

.table {
  width: 100%;
  height: 100%;
  border-collapse: separate;
  /* Para poder aplicar border-radius */
  border-spacing: 0;
  /* Elimina el espacio entre celdas */
}

.table thead th {
  color: black;
  background-color: #B2BD0099;
  /* Color de fondo para thead */
  border: none;
  /* Elimina los bordes de los th */
}

.table tbody tr:first-child td {
  border-top: none;
  /* Elimina el borde superior de la primera fila de tbody */
}

.table tbody td {
  border: none;
  /* Elimina los bordes de los td */
}



/* INDICADORES DETALLE CIUDAD */
.indicadorGanancia {
  color: #04B606;
  font-weight: 700;
}

.indicadorPerdida {
  color: red;
  font-weight: 700;
}


.containerOptionSucursalIndividual {}

.buttonOptionSucursalIndividual {
  background-color: #B2BD0066;
  border-radius: 5px;
  border: none;
  width: 4rem;
  height: 2rem;
  font-weight: 600;
}

.buttonOptionSucursalIndividualVer {
  background-color: #B2BD0066;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  width: 4rem;
  height: 2rem;
  font-weight: 600;
  padding: 0rem 3rem 0rem 3rem;
}

.swal2-confirm-custom {
  background-color: #04B606; /* Cambia el color de fondo del botón */
  color: #fff; /* Cambia el color del texto del botón */
  border-color: #04B606; /* Cambia el color del borde del botón */
}

.swal2-confirm-custom:hover {
  background-color: #B2BD0066; /* Cambia el color de fondo del botón al pasar el cursor */
  border-color: #B2BD0066; /* Cambia el color del borde del botón al pasar el cursor */
}

.plain-select {
  padding: 0 !important;
  --bs-form-select-bg-img:none !important;
  text-align: center;
}

.inputGuardarCambios{
  background-color: #B2BD0066;
  border: 2px solid rgba(211, 211, 211, 0.599);
  border-radius: 10px;
  height: 4em;
  width: 4em;
}

.textSolicitar{
  text-decoration: underline;
  font-size: 1em;
  width: 100%;
  text-align: center;
  cursor: pointer;
}


/* ELIMINAR SUCURSAL */

.inputEliminarSucursal{
  background-color: #B2BD0066;
  border: 2px solid rgba(211, 211, 211, 0.599);
  border-radius: 10px;
  height: 8em;
}

.razonEliminacion{
  font-size: 1em;
}

.button-modal-eliminacion{
  height: 3em;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #04B606;
}

.button-modal-eliminacionRegresar{
  height: 3em;
  border-radius: 5px;
  border: none;
  color: black;
  background-color: #B2BD0066;
}

.button-modal-eliminacionCancelar{
  height: 3em;
  border-radius: 5px;
  border: none;
  color: black;
  background-color: #b4bd008d;
}

.parrafoEliminacion{
  font-size: 1.2em;
  font-weight: 400;
}



/* SOLICITAR INSUMOS */
.inputSolicitarInsumos{
  border-radius: 5px;
  height: 2.3em;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-left: 0px solid black;
  border-right: 1px solid black;
}

.input-group-text{
  background-color: white;
  height: 2.3em;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-top-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
}

.list-item{
  height: 2.3em;
  border: 1px solid rgba(190, 190, 190, 0.702);
  border-radius: 5px;
}



/* SAIDA/CADUCIDAD */
.inputSalidaPassword{
  background-color: #B2BD0066;
  border: 2px solid rgba(211, 211, 211, 0.599);
  border-radius: 10px;
  height: 4em;
}



/* TRASLADO */





/* AJUSTES */
.inputCantidadStock{
  background-color: #B2BD0066;
  border: 2px solid rgba(211, 211, 211, 0.599);
  border-radius: 10px;
  height: 2.3em;
}



/* TABLA DE INFORMES */

.indicadorRendimiento{
  color: #04B606;
  font-weight: 500;
}

.indicadorAccionesGreen{
  background-color: #04b60783;
  color: black;
  border-radius: 20px;
  border: none;
}

.indicadorAccionesRed{
  background-color: #F14444;
  color: white;
  border-radius: 20px;
  border: none;
}

.indicadorAccionesYellow{
  background-color: #FDCD52;
  color: black;
  border-radius: 20px;
  border: none;
}



/* CREAR NUEVA SUCURSAL */

.inputCrearSucursal{
  height: 2.3em;
  background-color: #B2BD0066 !important;

}

.buttonDescriptivoNuevaSucursal{
  background-color: #ECECEC;
  border-radius: 5px;
  border: none;
  padding: .5rem 2rem .5rem 2rem
}

.text-omitir{
  color: #04B606;
  text-decoration: underline;
}