.mi-clase-icono {
    color: black;
    font-size: 24px;
  }

  .inputModalVerProducto{
    font-weight: 400 !important;
  }
  
  .button-login {
    color: white;
    padding: 10px;
    font-size: 1rem;
    width: 85vw !important;
    height: 5vh;
    background-color: #00B100;
    border-radius: 5px;
    border: none;

    /* Estilos para el botón */
}

.button-modal-ver-producto{
    color: white;
    padding: 10px;
    font-size: 1rem;
    width: 70% !important;
    font-weight: 500;
    height: 3em;
    background-color: #00B100;
    border-radius: 5px;
    border: none;
}

.{
    font-size: 1rem;
    font-weight: 400;
}

.calendarVerProductos{
    height: 1.8rem !important;
}

.textFirstCompra{
    background-color: #A7FFA8;
    border-top-left-radius: 5px;
}

.textFechaFirstCompra{
    background-color: #F4F4F4;
    border-top-right-radius: 5px;
}

.textSecondCompra{
    background-color: #FF8D8D;
    border-bottom-left-radius: 5px;

}

.textFechaSecondCompra{
    border-top: 1px solid black;
    background-color: #F4F4F4;
    border-bottom-right-radius: 5px;
}

.fontBold800{
    font-weight: 800 !important;
}
.fontBold900{
    font-weight: 900 !important;
}
.fontBold700{
    font-weight: 700 !important;
}
.fontBold600{
    font-weight: 600 !important;
}
.fontBold500{
    font-weight: 500 !important;
}
.fontBold400{
    font-weight: 400 !important;
}
.fontBold300{
    font-weight: 300 !important;
}