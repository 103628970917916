    .inputSucursal{
        border: 2px solid rgba(160, 158, 158, 0.419);
        background-color: #b4bd009b;
        border-radius: 8px;
        height: 2.3rem;
        margin-bottom: 7px;
    }

    .labelSucursal{
        font-weight: 600;
        font-size: 100%;
      }

  .inputAñadirInventarioMitad{
    border: none;
    border: 3px solid rgba(160, 158, 158, 0.419);
    background-color: #b4bd009b;
    border-radius: 8px;
    height: 2rem;
    margin-bottom: 30px;
  }

  .mapSucursales{
    border-radius: 10px !important;
    border: 3px solid rgba(220, 220, 220, 0.419) !important;
  }

   .optionSucursales{
    background-color: #e8e8e8;
   }


  .table-responsive-sucursales {
    border-radius: 15px;
    height: 100%;
    overflow: hidden; /* Asegura que el border-radius se aplique a los hijos */
    border: 1px solid #a4a4a465;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Opcional: sombra suave alrededor de la tabla */
  }

  .containerTableSucursales{
    height: 100%;
    min-height: 100%;
  }
  
  .table-sucursales {
    width: 100%;
    height: 100%;
    border-collapse: separate; /* Para poder aplicar border-radius */
    border-spacing: 0; /* Elimina el espacio entre celdas */
  }
  
  .table-sucursales  thead th {
    font-size: .7rem !important;
    color: black;
    background-color: #B2BD0099; /* Color de fondo para thead */
    border: none; /* Elimina los bordes de los th */
  }
  

  .table-sucursales  tbody tr {
    height: 1rem;
    font-size: .7rem !important;
    color: black;
  }

  .table-sucursales  tbody tr td{
  }
  

  .table-sucursales  tbody tr:first-child td {
    border-top: none; /* Elimina el borde superior de la primera fila de tbody */
  }
  
  .table-sucursales  tbody td {
    border: none; /* Elimina los bordes de los td */
  }
  
  .buttonSucursalesTable{
    background-color: #B2BD0066;
    border: none;
    height: 100%;
    width: 100%;
  }

  .buttonSucursalesTableActive{
    background-color: #04B606;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    height: 100%;
    width: 100%;
  }